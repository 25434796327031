<script>
import CRUDMixin from "@/mixins/CRUDMixin";

export default {
  mixins: [CRUDMixin],
  props: {
    isLoading: {
      default: false
    }
  },
  watch: {
    loading() {
      this.internalLoading = this.loading;
    }
  },
  computed: {
    internalLoading: {
      get() {
        return this.isLoading;
      },
      set(value) {
        this.$emit("update:isLoading", value);
      }
    }
  },
  methods: {
    handleSuccess(message) {
      this.reset();
      this.$emit("success", message);
    },
    handleError(message) {
      this.reset();
      this.$emit("error", message);
    }
  }
};
</script>