<template>
  <Subview class="main-content settings" :contentPadding="false">
    <v-layout slot="subview-content" column>
      <PatientsTable
        :patients="objects"
        :pagination.sync="pagination"
        :search.sync="search"
        :totalItems="totalItems"
        @success="handleSuccess"
        @error="handleError"
      ></PatientsTable>
    </v-layout>
  </Subview>
</template>

<script>
import PatientService from "@/services/patient.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import DataTableMixin from "@/mixins/DataTableMixin";
import _ from "lodash";

export default {
  mixins: [ProxyCRUDMixin, DataTableMixin],
  data() {
    return {
      pagination: {
        rowsPerPage: 10,
        page: 1,
        search: "",
        sortBy: "name",
        descending: false,
      },
      defaultSortBy: "name",
    };
  },
  components: {
    Subview: () => import("@/components/Subview"),
    PatientsTable: () => import("@/components/tables/PatientsTable"),
    LabelTextField: () => import("@/components/LabelTextField"),
  },
  methods: {
    fetch() {
      this.beforeRequest();
      PatientService.getPatients(this.pagination)
        .then((reply) => {
          this.totalItems = reply.count;
          this.objects = reply.results;//Array(20).fill(reply.results[0]);
          this.reset();
        })
        .catch(() => this.handleError("Coś poszło nie tak"));
    },
    fetchData: _.debounce(function () {
      this.fetch();
    }, 300),
  },
};
</script>